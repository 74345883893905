<template>
  <PeriodOfConstructionFormField :choices="choices" v-on="$listeners" />
</template>

<script>
import { Selectable } from 'chimera/all/components/models/Selectable'
import PeriodOfConstructionFormField from 'chimera/all/components/form/fields/periodOfConstruction/PeriodOfConstructionFormField.vue'

export default {
  name: 'PeriodOfConstructionFormPart',

  components: {
    PeriodOfConstructionFormField
  },

  /**
   * @returns {object}
   */
  data () {
    return {
      choices: [
        new Selectable(
          this.$i18n.t('field.period-of-construction.options.before-1975'),
          this.$i18n.t('field.period-of-construction.options.before-1975'),
          this.$i18n.t('field.period-of-construction.options.before-1975')
        ),
        new Selectable(
          this.$i18n.t(
            'field.period-of-construction.options.between-1975-and-1992'
          ),
          this.$i18n.t(
            'field.period-of-construction.options.between-1975-and-1992'
          ),
          this.$i18n.t(
            'field.period-of-construction.options.between-1975-and-1992'
          )
        ),
        new Selectable(
          this.$i18n.t('field.period-of-construction.options.after-1992'),
          this.$i18n.t('field.period-of-construction.options.after-1992'),
          this.$i18n.t('field.period-of-construction.options.after-1992')
        )
      ]
    }
  }
}
</script>
