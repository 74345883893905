<template>
  <TypeOfInsulationFormField :choices="choices" v-on="$listeners" />
</template>

<script>
import { Selectable } from 'chimera/all/components/models/Selectable'
import TSelectableWithTextField from 'chimera/all/components/elements/TSelectableWithTextField.vue'
import TypeOfInsulationFormField from 'chimera/all/components/form/fields/typeOfInsulation/TypeOfInsulationFormField.vue'

export default {
  name: 'TypeOfInsulationFormPart',

  components: {
    TypeOfInsulationFormField
  },

  /**
   * @returns {object}
   */
  data () {
    return {
      choices: [
        new Selectable(
          this.$i18n.t('field.type-of-insulation.options.roof-insulation'),
          this.$i18n.t('field.type-of-insulation.options.roof-insulation'),
          this.$i18n.t('field.type-of-insulation.options.roof-insulation')
        ),
        new Selectable(
          this.$i18n.t(
            'field.type-of-insulation.options.cavity-wall-insulation'
          ),
          this.$i18n.t(
            'field.type-of-insulation.options.cavity-wall-insulation'
          ),
          this.$i18n.t(
            'field.type-of-insulation.options.cavity-wall-insulation'
          )
        ),
        new Selectable(
          this.$i18n.t('field.type-of-insulation.options.floor-insulation'),
          this.$i18n.t('field.type-of-insulation.options.floor-insulation'),
          this.$i18n.t('field.type-of-insulation.options.floor-insulation')
        ),
        new Selectable(
          this.$i18n.t('field.type-of-insulation.options.double-glazing'),
          this.$i18n.t('field.type-of-insulation.options.double-glazing'),
          this.$i18n.t('field.type-of-insulation.options.double-glazing')
        ),
        new Selectable(
          this.$i18n.t('field.type-of-insulation.options.no-insulation'),
          this.$i18n.t('field.type-of-insulation.options.no-insulation'),
          this.$i18n.t('field.type-of-insulation.options.no-insulation')
        ),
        new Selectable(
          this.$i18n.t('global.other'),
          '',
          this.$i18n.t('global.other'),
          {
            component: TSelectableWithTextField,
            textField: {
              placeholder: this.$i18n.t('global.other')
            }
          }
        )
      ]
    }
  }
}
</script>
