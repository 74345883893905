<template>
  <FormModal title="Welk type isolatie is er al aanwezig in de woning?" show-back-btn>
    <template #body>
      <TypeOfInsulationFormStep :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import TypeOfInsulationFormStep from '~/components/form/steps/typeOfInsulation/TypeOfInsulationFormStep'

export default {
  components: {
    TypeOfInsulationFormStep,
    FormModal
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data () {
    return {
      headTitle: 'Type isolatie',
      headDescription: 'Geef aan naar wat voor type warmtepomp installatie je op zoek bent.',
      path: '/offertes-aanvragen/type-isolatie',
      progressValue: 30,
      checkoutStep: 2
    }
  }
}
</script>
